<template>
<router-view />
</template>

<script>
export default {
    name: 'TheBlankLayout',
    mounted: function() {
        document.body.getElementsByTagName('div')[0].setAttribute('class', 'kt-grid kt-grid--hor kt-grid--root');
    },
};
</script>

<style lang="css" src="socicon/css/socicon.css"></style>
<style lang="css" src="@fortawesome/fontawesome-free/css/all.min.css"></style>
<style lang="css" src="../../assets/plugins/line-awesome/css/line-awesome.css"></style>
<style lang="css" src="../../assets/plugins/flaticon/flaticon.css"></style>
<style lang="css" src="../../assets/plugins/flaticon2/flaticon.css"></style>

<style lang="scss" src="../../assets/sass/style.vue.scss"></style>
<style lang="scss" src="../../assets/sass/global/integration/frameworks/vue/_skins.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/header/skins/menu/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/aside/skins/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/brand/skins/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/header/skins/base/dark.scss"></style>
