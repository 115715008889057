var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet kt-portlet--mobile kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _c("div", { staticClass: "kt-portlet__head-label" }, [
              _vm.isNewUser
                ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _vm._v(" Add New User "),
                  ])
                : _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _vm._v(
                      " Edit User - " + _vm._s(_vm.staffUser.schoolEmail) + " "
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
              _c("div", { staticClass: "kt-portlet__head-group" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-sm btn-icon btn-clean btn-icon-md",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exit.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-close" })]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "form-group validated row pl-2 pr-2" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(" First Name: "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staffUser.firstName,
                      expression: "staffUser.firstName",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.isFirstNameValid ? "" : "is-invalid",
                  attrs: { type: "text" },
                  domProps: { value: _vm.staffUser.firstName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.staffUser, "firstName", $event.target.value)
                    },
                  },
                }),
                !_vm.isFirstNameValid
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Please enter a first name "),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "col-6" }, [
                _vm._v(" Last Name: "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staffUser.lastName,
                      expression: "staffUser.lastName",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.isLastNameValid ? "" : "is-invalid",
                  attrs: { type: "text" },
                  domProps: { value: _vm.staffUser.lastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.staffUser, "lastName", $event.target.value)
                    },
                  },
                }),
                !_vm.isLastNameValid
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Please enter a last name "),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "form-group validated row pl-2 pr-2" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(" Job Title: "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staffUser.jobTitle,
                      expression: "staffUser.jobTitle",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.isJobTitleValid ? "" : "is-invalid",
                  attrs: { type: "text" },
                  domProps: { value: _vm.staffUser.jobTitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.staffUser, "jobTitle", $event.target.value)
                    },
                  },
                }),
                !_vm.isJobTitleValid
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(" Please enter a job title. "),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "form-text text-muted" }, [
                  _vm._v(
                    " This field is free-formed and provided for your use. "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "col-6" }, [
                _vm._v(" " + _vm._s(_vm.user.school.name) + " Role: "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.staffUser.roleId,
                        expression: "staffUser.roleId",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.staffUser,
                          "roleId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  _vm._l(_vm.roles, function (role) {
                    return _c(
                      "option",
                      {
                        key: `role_${role.roleId}_${_vm.staffUser.userId}`,
                        domProps: {
                          value: role.roleId,
                          selected: role.roleId == _vm.staffUser.roleId,
                        },
                      },
                      [_vm._v(" " + _vm._s(role.name) + " ")]
                    )
                  }),
                  0
                ),
                _vm.staffUser.roleId == 4
                  ? _c("span", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        " School Admins have access to unspecified permissions, such as school settings pages. "
                      ),
                    ])
                  : _vm._e(),
                _vm.staffUser.roleId == 7
                  ? _c("span", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        " Staff members only have access to the permissions checked below, unless they are teaching courses, in which case the user will be treated as a teacher. "
                      ),
                    ])
                  : _vm._e(),
                _vm.staffUser.roleId == 6
                  ? _c("span", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        " Teachers always have access to students within their courses as supplied by the SIS. Additional access permissions can be granted below. "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "form-group validated row pl-2 pr-2" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(" School Email Address: "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.staffUser.schoolEmail,
                      expression: "staffUser.schoolEmail",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.isValidSchoolDomain ? "" : "is-invalid-warn",
                  attrs: {
                    placeholder: `e.g. barack.obama@${_vm.mySchoolDomain}`,
                    type: "email",
                    disabled: !_vm.user.isSuperAdmin && !_vm.isNewUser,
                  },
                  domProps: { value: _vm.staffUser.schoolEmail },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.staffUser,
                        "schoolEmail",
                        $event.target.value
                      )
                    },
                  },
                }),
                !_vm.isValidSchoolDomain
                  ? _c("div", { staticClass: "invalid-warn-feedback" }, [
                      _vm._v(" Please enter a valid email address ending in "),
                      _c("code", [_vm._v("@" + _vm._s(_vm.mySchoolDomain))]),
                      _vm.mySchoolDomain !== _vm.nycDomain
                        ? _c("span", [
                            _vm._v(" or "),
                            _c("code", [_vm._v("@" + _vm._s(_vm.nycDomain))]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "form-text text-muted" }, [
                  _vm._v(
                    " This field should not be edited. Editing may disable this user's authentication. "
                  ),
                ]),
              ]),
              !_vm.$_userMixins_isGoogleDisabled
                ? _c("div", { staticClass: "col-6" }, [
                    _vm._v(" Google Email Address: "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.staffUser.googleEmail,
                          expression: "staffUser.googleEmail",
                        },
                      ],
                      staticClass: "form-control",
                      class: _vm.isValidGoogleDomain ? "" : "is-invalid-warn",
                      attrs: {
                        placeholder: `e.g. barack.obama@${_vm.myGoogleDomain}`,
                        type: "email",
                      },
                      domProps: { value: _vm.staffUser.googleEmail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.staffUser,
                            "googleEmail",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    !_vm.isValidGoogleDomain
                      ? _c("div", { staticClass: "invalid-warn-feedback" }, [
                          _vm._v(
                            " Please enter a valid email address ending in "
                          ),
                          _c("code", [
                            _vm._v("@" + _vm._s(_vm.myGoogleDomain)),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "kt-section__body col-lg-12 pt-4" }, [
              _c(
                "div",
                {
                  staticClass: "form-group row pb-4 pl-4 pr-4 sg-bordered-row",
                },
                [
                  _c("span", { staticClass: "sg-border-label" }, [
                    _vm._v("Permissions"),
                  ]),
                  _c("div", { staticClass: "col-12 pt-3" }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title: "Can Create/Delete Courses and manage rostering",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .canManageCourses,
                                expression:
                                  "staffUser.userPermissions.canManageCourses",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions.canManageCourses
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canManageCourses,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .canManageCourses,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canManageCourses,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canManageCourses",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canManageCourses",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canManageCourses",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can manage courses and rostering "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                    _c(
                      "label",
                      { staticClass: "kt-checkbox kt-checkbox--success" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.staffUser.userPermissions
                                  .accessAllStudentPortfolios,
                              expression:
                                "staffUser.userPermissions.accessAllStudentPortfolios",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.staffUser.userPermissions
                                .accessAllStudentPortfolios
                            )
                              ? _vm._i(
                                  _vm.staffUser.userPermissions
                                    .accessAllStudentPortfolios,
                                  null
                                ) > -1
                              : _vm.staffUser.userPermissions
                                  .accessAllStudentPortfolios,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.staffUser.userPermissions
                                    .accessAllStudentPortfolios,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.staffUser.userPermissions,
                                      "accessAllStudentPortfolios",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.staffUser.userPermissions,
                                      "accessAllStudentPortfolios",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.staffUser.userPermissions,
                                  "accessAllStudentPortfolios",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" Allow access to all Student Portfolios "),
                        _c("span"),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "Read only access to all teacher portfolio pages",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .accessAllTeacherPortfolios,
                                expression:
                                  "staffUser.userPermissions.accessAllTeacherPortfolios",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .accessAllTeacherPortfolios
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .accessAllTeacherPortfolios,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .accessAllTeacherPortfolios,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .accessAllTeacherPortfolios,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "accessAllTeacherPortfolios",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "accessAllTeacherPortfolios",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "accessAllTeacherPortfolios",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Allow access to all Teacher Portfolios "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "This allows a user to see all non-confidential anecdotals created in this school.\n                            The user may not have permission to access all anecdotal participants, if porfolio access is not also granted.",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .accessAllAnecdotalEvents,
                                expression:
                                  "staffUser.userPermissions.accessAllAnecdotalEvents",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .accessAllAnecdotalEvents
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .accessAllAnecdotalEvents,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .accessAllAnecdotalEvents,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .accessAllAnecdotalEvents,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "accessAllAnecdotalEvents",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "accessAllAnecdotalEvents",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "accessAllAnecdotalEvents",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Allow access to all Anecdotal Events "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "This user can edit any and all anecdotals they have permission to view",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .editSchoolAnecdotalEvents,
                                expression:
                                  "staffUser.userPermissions.editSchoolAnecdotalEvents",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .editSchoolAnecdotalEvents
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .editSchoolAnecdotalEvents,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .editSchoolAnecdotalEvents,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .editSchoolAnecdotalEvents,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "editSchoolAnecdotalEvents",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "editSchoolAnecdotalEvents",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "editSchoolAnecdotalEvents",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " Can edit all anecdotals events in the school "
                          ),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "If this user teaches courses, then they can choose a grade template from school templates",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .canChooseGradeTemplate,
                                expression:
                                  "staffUser.userPermissions.canChooseGradeTemplate",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .canChooseGradeTemplate
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canChooseGradeTemplate,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .canChooseGradeTemplate,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canChooseGradeTemplate,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canChooseGradeTemplate",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canChooseGradeTemplate",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canChooseGradeTemplate",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " Can choose from available grade templates for courses taught. "
                          ),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: { title: "This user can manage purchase orders." },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .canManagePurchaseOrders,
                                expression:
                                  "staffUser.userPermissions.canManagePurchaseOrders",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .canManagePurchaseOrders
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canManagePurchaseOrders,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .canManagePurchaseOrders,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canManagePurchaseOrders,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canManagePurchaseOrders",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canManagePurchaseOrders",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canManagePurchaseOrders",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can manage purchase orders. "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "Can create and submit Bug Reports to SyncGrades",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .canSubmitBugReports,
                                expression:
                                  "staffUser.userPermissions.canSubmitBugReports",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .canSubmitBugReports
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canSubmitBugReports,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .canSubmitBugReports,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canSubmitBugReports,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canSubmitBugReports",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canSubmitBugReports",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canSubmitBugReports",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can submit bug reports "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "Can create messages to other school and staff users",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .canMessageSchoolUsers,
                                expression:
                                  "staffUser.userPermissions.canMessageSchoolUsers",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .canMessageSchoolUsers
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canMessageSchoolUsers,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .canMessageSchoolUsers,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canMessageSchoolUsers,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canMessageSchoolUsers",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canMessageSchoolUsers",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canMessageSchoolUsers",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can send communications to school users "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title: "Can create messages to students and guardians",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions
                                    .canMessageHomeUsers,
                                expression:
                                  "staffUser.userPermissions.canMessageHomeUsers",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions
                                  .canMessageHomeUsers
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canMessageHomeUsers,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions
                                    .canMessageHomeUsers,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canMessageHomeUsers,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canMessageHomeUsers",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canMessageHomeUsers",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canMessageHomeUsers",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can send communications to home users "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title:
                          "Can send notifications to other users via email",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions.canNotifyEmail,
                                expression:
                                  "staffUser.userPermissions.canNotifyEmail",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions.canNotifyEmail
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions
                                      .canNotifyEmail,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions.canNotifyEmail,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions
                                      .canNotifyEmail,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canNotifyEmail",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canNotifyEmail",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canNotifyEmail",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can notify other users via email "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "col-4 pb-2 pl-1",
                      attrs: {
                        title: "Can send notifications to other users via SMS",
                      },
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--success" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.staffUser.userPermissions.canNotifySms,
                                expression:
                                  "staffUser.userPermissions.canNotifySms",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.staffUser.userPermissions.canNotifySms
                              )
                                ? _vm._i(
                                    _vm.staffUser.userPermissions.canNotifySms,
                                    null
                                  ) > -1
                                : _vm.staffUser.userPermissions.canNotifySms,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.staffUser.userPermissions.canNotifySms,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canNotifySms",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.staffUser.userPermissions,
                                        "canNotifySms",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.staffUser.userPermissions,
                                    "canNotifySms",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" Can notify other users via SMS "),
                          _c("span"),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__foot" }, [
            _c("div", { staticClass: "kt-form__actions" }, [
              !_vm.isNewUser
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        disabled: !_vm.isFormValid,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.deleteUser()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-trash" }),
                      _vm._v(" Delete User "),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary alignment pull-right",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    disabled: !_vm.isFormValid,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.saveUser()
                    },
                  },
                },
                [
                  !_vm.saving
                    ? _c("i", { staticClass: "fa fa-save" })
                    : _vm._e(),
                  _vm._v(
                    " " + _vm._s(_vm.saving ? "Saving..." : "Save User") + " "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }